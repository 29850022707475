import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/contact.css"

export default function ContactPage(props) {
  return (
    <Layout>
      <Seo title="Contact Us" />
      <div className="formContainer">
        <h1>Contact Us</h1>
        <form
          action="https://marsgroupusa.net/static/api/contact.php"
          method="POST"
          autocomplete="off"
        >
          <div className="inputContainer">
            <label htmlFor="email-input">
              <h5>Email</h5>
            </label>
            <input type="email" name="email" id="email-input" />
          </div>
          <div className="inputContainer">
            <label htmlFor="fname-input">
              <h5>First Name</h5>
            </label>
            <input type="text" id="fname-input" name="firstname" />
          </div>
          <div className="inputContainer">
            <label htmlFor="lname-input">
              <h5>Last Name</h5>
            </label>
            <input type="text" id="lname-input" name="lastname" />
          </div>
          <input type="text" id="website-input" name="website" />
          <div className="inputContainer">
            <label htmlFor="message-input">
              <h5>Message</h5>
            </label>
            <textarea id="message-input" name="message"></textarea>
          </div>
          <div className="inputContainer">
            <button type="submit" className="submitBttn">
              Send
            </button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
